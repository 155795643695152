

















import Vue from 'vue';
import Component from 'vue-class-component';
import EventListItem from '@/components/EventListItem.vue';
import { eventApi } from '@/api/eventApi';
import { IEventView } from '@/interfaces/_auto/api';

@Component({
  components: { EventListItem },
})
export default class Events extends Vue {

  eventList: IEventView[] = [];

  async mounted() {
    await this.loadEventList();
  }

  async loadEventList() {
    const { data } = await eventApi.getEvents();
    this.eventList = data;
  }
}


